import { IdentityEmployee } from "./identity-employee";
import { Company } from "./company";
import { Client } from "./client";
import { EmployeeState } from "./employee-state";
import { UserPreferences } from "./preferences/user-preferences";
var pjson = require("../../../package.json");

export class User {
  private activeCompanyIndex = 0;
  private activeClientIndex = 0;
  private mock = false;
  private version = pjson.version;

  employees: IdentityEmployee[] = [];
  cacheExpiration = 0;

  constructor() {
    this.cacheExpiration = new Date().getTime() + 50 * 60 * 1000;
    this.mock = false;
  }

  useCompany(companyId: string): void {
    const index = this.employees.findIndex((c) => c.companyId === companyId);
    if (index < 0) {
      throw new Error("Company id not found");
    }
    this.activeCompanyIndex = index;
    this.saveToCache();
  }

  public getCompany(): Company {
    if (this.employees?.length > this.activeCompanyIndex) {
      const employee = this.employees[this.activeCompanyIndex];
      const company = new Company();
      company.id = employee.companyId;
      company.name = employee.companyName;
      company.clients = employee.clients;
      return company;
    }
    throw new Error("No Company found");
  }

  public getEmployee(): IdentityEmployee {
    if (this.employees?.length > this.activeCompanyIndex) {
      return this.employees[this.activeCompanyIndex];
    }
    throw new Error("No Employee found");
  }

  public getFullname(): string {
    const employee = this.getEmployee();
    return `${employee.firstName} ${employee.lastName}`;
  }

  public isActive(): boolean {
    return (
      this.employees?.length > this.activeCompanyIndex &&
      this.employees[this.activeCompanyIndex].employeeState === EmployeeState.Active
    );
  }

  public useClient(clientId: string): void {
    const index = this.employees[this.activeCompanyIndex].clients.findIndex((c) => c.id === clientId);
    if (index < 0) {
      throw new Error("Client id not found");
    }
    this.activeClientIndex = index;
    this.saveToCache();
  }

  public getClient(): Client {
    if (this.employees?.length > this.activeCompanyIndex) {
      const employee = this.employees[this.activeCompanyIndex];

      if (employee.clients?.length > this.activeClientIndex) {
        const client = employee.clients[this.activeClientIndex];
        client.companyId = employee.companyId;
        return client;
      }
    }

    throw new Error("No client found");
  }

  public preference(): UserPreferences {
    return this.getEmployee().preferences;
  }

  public setPreference(preference: UserPreferences) {
    this.getEmployee().preferences = preference;
    this.saveToCache();
  }

  public getBodyCssClass(isAuthenticated: boolean): string {
    if (!isAuthenticated) {
      return "c-guest";
    }

    if (!this.employees || this.employees.length === 0) {
      return "c-guest";
    }

    if (this.employees?.length < this.activeCompanyIndex) {
      return "c-guest";
    }

    const employee = this.employees[this.activeCompanyIndex];

    if (employee.employeeState !== EmployeeState.Active) {
      return "c-guest";
    }

    const cssTheme = employee?.preferences?.theme
      ? `c-theme-${employee.preferences.theme.toLowerCase()}`
      : "c-theme-default";

    const dataTableStyle = employee?.preferences?.dataTableStyle
      ? `c-datatable-${employee.preferences.dataTableStyle.toLowerCase()}`
      : "c-datatable-default";

    return `c-auth ${cssTheme} ${dataTableStyle}`;
  }

  public saveToCache(): void {
    localStorage.setItem(`${this.getEmployee()?.identityId ?? ""}.id`, JSON.stringify(this)); //Cache user
  }

  static clearCache(identityId: string): void {
    localStorage.removeItem(`${identityId}.id`); //Clear user from localstorage
  }

  static loadFromCache(identityId: string): User | null {
    const userJson = localStorage.getItem(`${identityId}.id`);
    if (userJson) {
      const user = new User();
      Object.assign(user, JSON.parse(userJson));

      if (user.version !== pjson.version) {
        return null;
      }

      if (user.cacheExpiration > new Date().getTime() && user.employees.length > 0) {
        if (user.getEmployee().identityId === identityId) {
          return user;
        }
      }
    }
    return null;
  }

  static useMockedToken(identityId: string): boolean {
    const userJson = localStorage.getItem(`${identityId}.id`);

    if (userJson) {
      const user = new User();
      Object.assign(user, JSON.parse(userJson));
      return user.mock;
    }

    return false;
  }
}
